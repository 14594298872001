.App {
  text-align: left;
}

.App-logo {
  pointer-events: none;
}

@font-face {
  font-family: "FontAwersome";
  src: local("FontAwersome"),
    url("../public/assets/fonts/FontAwersomeBrands.ttf") format("truetype");
  font-weight: normal;
}